import DatapointsImg from "../../../../content/assets/winr-data-7-datapoints__light_2.png"
import BadgesImg from "../../../../content/assets/gdpr-and-ccpa-readiness-badges.png"
import BannerImg from "../../../../content/assets/identity-resolution-providers.png"

const identityResolutionProvidersPageData = {
  hero_banner: {
    heading: "Identity Resolution Providers",
    background_colour: "#3f2e6e",
    text_colour: "#fff",
    subheading: `<p>For businesses that are building their Identity Graphs or creating linkage between devices and people, WINR Data provides a single access point to digital and terrestrial data sets providing recency, consistency and scale.</p>`,
    include_image_slider: true,
    slider_images: [
      {
        url: BannerImg,
        alt: "",
      },
    ],
  },
  image_text_split: {
    background_colour: "#cbeceb",
    intro_enabled: false,
    title: "Identity Resolution Applications",
    content_type: "simple_text",
    text: `<p class="lead">Third party cookie deprecation has accelerated the shift by the industry to adopt identity graphs built around first, second and third-party data.</p><p>WINR Data provides platform vendors with a single access point to our international data consortium; a dynamic linkage repository of global, terrestrial identifiers.</p>
    <p>These identifiers include a history of name variations, previous postal addresses, mobile numbers, multiple email addresses, IP addresses and DOB.</p>
    <p>The data consortium can also expand your marketing taxonomy through the provision of data elements such as Age, Gender, Affluence and our marketing persona known as the <a href="/data-and-insights/">Kaptiv Audience Segment (KAS)</a>.</p>
    `,
    image: {
      url: DatapointsImg,
      alt: "Kaptiv Audience Segments (KAS)",
    },
    remove_horizontal_margin: false,
    image_alignment: "right",
    reverse_order_mobile: false,
  },
  image_text_split_2: {
    background_colour: "#000000",
    text_colour: "#ffffff",
    intro_enabled: true,
    intro_title: "Data Attributes",
    intro_text: `<p>WINR Data is committed to bringing together the best data sources for global electronic identity verification, and to ensuring our sources comply with all applicable data privacy laws and regulations. Typical commercial deals require the following fields (where available):</p>
      <ul class="content-list two-col-list row">
        <li class="col md-6">First Name</li>
        <li class="col md-6">Last Name</li>
        <li class="col md-6">Street Address</li>
        <li class="col md-6">Suburb</li>
        <li class="col md-6">State</li>
        <li class="col md-6">Postcode</li>
        <li class="col md-6">Mobile Number</li>
        <li class="col md-6">Email</li>
        <li class="col md-6">DOB (dd/mm/ccyy)</li>
        <li class="col md-6">IP Address (If Available)</li>
        <li class="col md-6">Recency/Date Created (dd/mm/ccyy)</li>
        <li class="col md-6">Id</li>
      </ul> 
      <p>We receive file updates as standard on a quarterly basis but can be upgraded where required and if commercially viable.</p>
      <p style="margin-bottom: 0;">WINR Data continues to expand our consortium of data partners, looking for the best in-country sources of information to integrate directly into our Data as a Service platform to help meet this growing market demand.</p>     
    `,
    content_type: "simple_text",
    remove_horizontal_margin: false,
  },
  image_text_split_3: {
    background_colour: "#fdfdfd",
    intro_enabled: true,
    intro_title: "Comfort in Compliance",
    intro_text: ``,
    text: `<p>WINR Data's key focus it to deliver data that has passed the robust compliance checks in line with the philosophy of Transparency, Compliance and Consent.</p>
      <p>Working with our Compliance Advisory Consultants, we work through a privacy questionnaire/due diligence check list for each data partner in a standard format and present them to you for review. If extra detail is required, we work with each of the data partners to provide the relevant information.</p>
      <p>If any partner fails to reach the required standards of the client then that file is simply excluded from the programme until the compliance is remedied and the relevant standards are met.</p>
      <p>Our information security systems have been implemented to deliver an effective privacy and security program consistent with global data protection frameworks such as GDPR, CCPA and the Australian Privacy Act.</p>
      <p style="margin-bottom: 0;">All our solutions are built and hosted on AWS to inherit the most comprehensive compliance controls available globally. AWS supports more security standards and compliance certifications than any other offering, including PCI-DSS, HIPAA/HITECH, FedRAMP, GDPR, FIPS 140-2, and NIST 800-171, helping customers satisfy compliance requirements for virtually every regulatory agency around the globe.</p>`,
    content_type: "simple_text",
    image: {
      url: BadgesImg,
      alt: "GDPR and CCPA Ready",
    },
    remove_horizontal_margin: false,
    image_alignment: "right",
    reverse_order_mobile: false,
  },
}

export { identityResolutionProvidersPageData }
